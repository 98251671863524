@import url('https://use.typekit.net/stq6ykx.css');
@import url('https://fonts.googleapis.com/css?family=Rubik&display=swap&text=1234567890.,msKM');

/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
@tailwind utilities;

/* purgecss start ignore */
#HW_badge_cont {
  @apply -mt-10 ml-2;
  position: absolute !important;
}

#HW_frame_cont {
  @apply mt-5;
}
/* purgecss end ignore */

.no-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.floating-1 {
  animation-name: floating-1;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating-1 {
  0 {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 6px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

.floating-2 {
  animation-name: floating-2;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating-2 {
  0 {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, -8px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

.floating-3 {
  animation-name: floating-3;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating-3 {
  0 {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 12px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
