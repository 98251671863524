.legal-text {
  & h1 {
    @apply text-blue-900 font-semibold text-2xl mb-4;

    & strong {
      @apply font-bold;
    }
  }

  & h2 {
    @apply text-blue-800 font-semibold text-xl mb-4 mt-6;
  }

  & h3 {
    @apply text-gray-800 font-semibold text-lg mb-2 mt-3;
  }

  & h4 {
    @apply text-gray-800 font-semibold mb-1 mt-3;
  }

  & hr {
    @apply my-8;
  }

  & a {
    @apply text-blue-500;
  }

  & p {
    @apply mb-2 text-gray-600;
  }

  & ul {
    @apply list-outside list-disc ml-5 mb-2 text-gray-600;
  }

  & strong {
    @apply font-semibold;
  }
}