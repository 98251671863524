.lh-report {
  min-width: auto !important;
}

.lh-topbar {
  display: none !important;
}

.lh-sticky-header--visible {
  top: -1px !important;
}

.lh-generated {
  display: none !important;
}
